import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";
import { ModalDeSucesso } from "../formularioAluno/components/modalSucesso";

const EditarCursoContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  margin: 0 auto;
  max-width: 85%;
`;

const CadastrarCurso = () => {
  const navigate = useNavigate();
  const [curso, setCurso] = useState({
    nome: "",
    valor: "",
    dataInicio: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const form = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    form.current.classList.remove("was-validated");

    if (form.current.checkValidity() === false) {
      form.current.classList.add("was-validated");
      return;
    }
    try {
      await api.post(`/cursos/`, curso, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShowModal(true);
    } catch (e) {
      console.log(e.response);
      setError(e.response.data.message);
    }
  };

  const handleChange = (event) => {
    setCurso({
      ...curso,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <BaseScreen>
      {error && <Alert variant="danger">{error}</Alert>}
      <Alert variant="info" className="p-3 mb-0 text-center alert-grande">
        <h2>Cadastro de Cursos</h2>
      </Alert>
      <EditarCursoContainer>
        <Form onSubmit={handleSubmit} ref={form} noValidate>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="nome"
              value={curso.nome}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="text"
              name="valor"
              value={curso.valor}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Data de Início</Form.Label>
            <Form.Control
              type="date"
              name="dataInicio"
              value={curso.dataInicio}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button type="submit" className="mt-3">
            Cadastrar
          </Button>
        </Form>
      </EditarCursoContainer>
      <ModalDeSucesso
        closeModal={() => {
          setShowModal(false);
          navigate("/cursos");
        }}
        showModal={showModal}
        nome={curso.nome}
        isAluno={false}
      />
    </BaseScreen>
  );
};

export default CadastrarCurso;
