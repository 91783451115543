import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";
import Input from "./components/input";
import InputMask from "./components/inputMask";
import { ModalConflito } from "./components/modalConflito";
import { ModalDeSucesso } from "./components/modalSucesso";
import { SelectEstado } from "./components/selectEstado";
import { SelectNivel } from "./components/selectNivel";
import moment from "moment";
import styled from "@emotion/styled";

const Formulario = () => {
  const [cursos, setCursos] = useState([]);
  const [cursosSelecionados, setCursosSelecionados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const [endereco, setEndereco] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCep] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numResidencial, setNumResidencial] = useState("");
  const [nivelEscolar, setNivelEscolar] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [conflictModal, setConflictModal] = useState(false);
  const [obs, setObs] = useState("");
  const navigate = useNavigate();

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin-top: 10px;
    background-color: #fff;
  `;

  function limparForm() {
    setNome("");
    setCep("");
    setCidade("");
    setEstado("");
    setNivelEscolar("");
    setNumResidencial("");
    setDataNasc("");
    setEndereco("");
    setTelefone("");
    setEmail("");
    setCpf("");
    setObs("");
    setCursosSelecionados([]);
  }

  const cadastrarAluno = async (cursosIds) => {
    const dataFormatada = moment(dataNasc)
      .local()
      .startOf("day")
      .format("YYYY-MM-DD");

    try {
      await api({
        method: "post",
        url: `/alunos`,
        data: {
          cpf: cpf,
          nome: nome,
          dataNascimento: dataFormatada,
          endereco: endereco,
          cep: cep,
          cidade: cidade,
          uf: estado,
          numResidencial: Number(numResidencial),
          numTelefone: telefone,
          email: email,
          nivelEscolar: nivelEscolar,
          cursosIds,
          observacao: obs,
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShowModal(true);
    } catch (e) {
      if (e.response.status === 409) {
        setConflictModal(true);
      }
      console.log("error: ", e.response);
      limparForm();
    }
  };

  useEffect(() => {
    const cepRegex = /^\d{5}-\d{3}$/;
    const ehCepValido = cep.match(cepRegex);
    if (ehCepValido) {
      fetchCep();
    }

    async function fetchCep() {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        setEndereco(response.data.logradouro);
        setCidade(response.data.localidade);
        setEstado(response.data.uf);
        console.log(estado);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [cep]);

  const onSubmit = async () => {
    const form = formRef.current;

    form.classList.remove("was-validated");

    const cursosSelecionadosIds = cursosSelecionados.map((cursoId) =>
      Number(cursoId)
    );

    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
      return;
    }

    await cadastrarAluno(cursosSelecionadosIds);
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/");
      return;
    }
    setIsLoading(true);
    async function getCursos() {
      try {
        const response = await api({
          method: "get",
          url: `/cursos`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCursos(response.data);
      } catch (e) {
        console.log(e.response);
      }
      setIsLoading(false);
    }
    getCursos();
  }, [navigate]);

  return (
    <BaseScreen>
      <Alert variant="info" className="p-3 mb-5 text-center alert-grande">
        <h2>Cadastro de Alunos</h2>
      </Alert>
      <div className="container mt-4">
        <form
          className="row g-3 needs-validation"
          ref={formRef}
          id="form"
          noValidate
        >
          <div className="row mb-3">
            <Input
              type={"text"}
              colunaClass={"col-md-8"}
              labelFor={"nome"}
              labelInput={"Nome:"}
              placeholder={"Informe o nome do aluno"}
              erro={"erroNome"}
              errolabel={"Nome inválido!"}
              onChange={setNome}
              value={nome}
            />

            <Input
              type={"date"}
              colunaClass={"col-md-4"}
              labelFor={"dataNasc"}
              labelInput={"Nascimento:"}
              erro={"erroData"}
              errolabel={"Por favor, informe sua data de nascimento!"}
              onChange={(date) => setDataNasc(date)}
              value={dataNasc}
            />
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="endereco" className="form-label">
                Edereço:
              </label>
              <input
                type="text"
                className={"form-control"}
                id="endereco"
                name="endereco"
                required
                placeholder="Endereço residencial"
                aria-describedby="erroEndereco"
                onChange={(event) => setEndereco(event.target.value)}
                value={endereco}
              />
              <div className="invalid-feedback" id="erroEndereco">
                Informe seu endereço!
              </div>
            </div>

            <Input
              type={"number"}
              colunaClass={"col-md-2"}
              labelFor={"numRes"}
              labelInput={"Número:"}
              placeholder={"Nº"}
              erro={"erroNumRes"}
              errolabel={"Informe o número!"}
              onChange={setNumResidencial}
              value={numResidencial}
            />

            <InputMask
              type={"text"}
              mask="99999-999"
              colunaClass={"col-md-4"}
              labelFor={"cep"}
              labelInput={"CEP:"}
              placeholder={"CEP"}
              erro={"erroCep"}
              errolabel={"Cep inválido!"}
              onChange={setCep}
              value={cep}
              isCep={true}
            />
          </div>

          <div className="row mb-3">
            <Input
              type={"text"}
              colunaClass={"col-md-4"}
              labelFor={"cidade"}
              labelInput={"Cidade:"}
              placeholder={"Informe a cidade"}
              erro={"erroCidade"}
              errolabel={"Cidade inválida!"}
              onChange={setCidade}
              value={cidade}
            />

            <SelectEstado
              setEstado={(ev) => setEstado(ev.target.value)}
              value={estado}
            />

            <InputMask
              type={"text"}
              colunaClass={"col-md-3"}
              labelFor={"cpf"}
              mask="999.999.999-99"
              labelInput={"CPF:"}
              placeholder={"Informe o CPF"}
              erro={"erroCPF"}
              errolabel={"CPF inválido!"}
              onChange={setCpf}
              value={cpf}
              isCep={false}
            />

            <InputMask
              type={"text"}
              mask="(99) 9 9999-9999"
              colunaClass={"col-md-3"}
              labelFor={"telefone"}
              labelInput={"Telefone:"}
              placeholder={"Informe o número de telefone"}
              erro={"erroTelefone"}
              errolabel={"Informe o número de celular!"}
              onChange={setTelefone}
              value={telefone}
            />
          </div>

          <div className="row mb-3">
            <Input
              type={"text"}
              colunaClass={"col-md-4"}
              labelFor={"email"}
              labelInput={"Email:"}
              placeholder={"Informe seu Email"}
              erro={"erroEmail"}
              errolabel={"Email inválido!"}
              onChange={setEmail}
              value={email}
            />

            <SelectNivel
              setNivelEscolar={(ev) => setNivelEscolar(ev.target.value)}
            />
          </div>

          <div className="row mb-3">
            <Input
              type={"text"}
              colunaClass={"col-md-12"}
              labelFor={"obs"}
              labelInput={"Observações:"}
              placeholder={"Observações opcionais"}
              onChange={setObs}
              value={obs}
              isObs={true}
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="nomeCurso" className="form-label">
              Cursos
            </label>
            {isLoading ? (
              <LoadingContainer>
                <Spinner animation="border" variant="primary" />
              </LoadingContainer>
            ) : (
              <div style={{ columnCount: 3 }}>
                {cursos.map(
                  (curso) =>
                    curso.ativo === 1 && (
                      <div key={curso.id} className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`curso-${curso.id}`}
                          value={curso.id}
                          onChange={(e) => {
                            const cursoId = e.target.value;
                            if (
                              cursosSelecionados.some(
                                (cursoSel) => cursoSel === cursoId
                              )
                            ) {
                              setCursosSelecionados(
                                cursosSelecionados.filter(
                                  (id) => id !== cursoId
                                )
                              );
                            } else {
                              setCursosSelecionados([
                                ...cursosSelecionados,
                                cursoId,
                              ]);
                            }
                          }}
                          checked={cursosSelecionados.includes(
                            curso.id.toString()
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`curso-${curso.id}`}
                        >
                          {curso.nome}
                        </label>
                      </div>
                    )
                )}
              </div>
            )}
          </div>

          <div className="row mt-3">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary"
                onClick={onSubmit}
                type="button"
              >
                Cadastrar
              </button>
            </div>
          </div>
        </form>
      </div>
      <ModalDeSucesso
        closeModal={() => {
          setShowModal(false);
          limparForm();
        }}
        showModal={showModal}
        nome={nome}
        isAluno={true}
      />
      <ModalConflito
        showModal={conflictModal}
        closeModal={() => setConflictModal(false)}
      />
    </BaseScreen>
  );
};

export default Formulario;
