import NavBar from "../../components/navbar";

export const BaseScreen = ({ children }) => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      {children}
    </div>
  );
};
