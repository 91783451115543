import ReactInputMask from "react-input-mask";

const InputMask = ({
  colunaClass,
  labelFor,
  labelInput,
  placeholder,
  erro,
  errolabel,
  type,
  onChange,
  value,
  mask,
  isCep,
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };
  return (
    <div className={colunaClass}>
      <label htmlFor={labelFor} className="form-label">
        {labelInput}
      </label>
      <ReactInputMask
        type={type}
        className="form-control"
        id={labelFor}
        name={labelFor}
        required={isCep ? false : true}
        placeholder={placeholder}
        aria-describedby={erro}
        onChange={handleInputChange}
        value={value}
        mask={mask}
      />
      <div className="invalid-feedback" id={erro}>
        {errolabel}
      </div>
    </div>
  );
};

export default InputMask;
