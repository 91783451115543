import { useEffect, useState } from "react";
import { Alert, Button, Modal, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";
import styled from "@emotion/styled";
import { FaCheck, FaTimes } from "react-icons/fa";
import { RiDeleteBin6Line, RiPencilLine } from "react-icons/ri";

const ListaCursos = () => {
  const [cursos, setCursos] = useState([]);
  const [cursoToDelete, setCursoToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nomeCursoToDelete, setNomeCursoToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    background-color: #fff;
  `;

  useEffect(() => {
    setIsLoading(true);
    async function getCursos() {
      try {
        const response = await api({
          method: "get",
          url: `/cursos`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCursos(response.data);
        setIsLoading(false);
      } catch (e) {
        console.log(e.response);
        setIsLoading(false);
      }
    }
    getCursos();
  }, [navigate]);

  const handleDeleteCurso = async (curso) => {
    setCursoToDelete(curso.id);
    setNomeCursoToDelete(curso.nome);
    setShowModal(true);
  };

  const confirmDeleteCurso = async () => {
    try {
      await api({
        method: "delete",
        url: `/cursos/${cursoToDelete}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCursos((prevCursos) =>
        prevCursos.filter((curso) => curso.id !== cursoToDelete)
      );
      setCursoToDelete(null);
      setShowModal(false);
    } catch (e) {
      console.log(e.response);
    }
  };

  function ajustarFormatoData(dataOriginal) {
    const data = new Date(dataOriginal);
    let dia = data.getDate() + 1;
    let mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    mes = mes <= 9 ? `0${mes}` : mes;
    dia = dia <= 9 ? `0${dia}` : dia;

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
      </LoadingContainer>
    );
  }

  return (
    <BaseScreen>
      <Alert variant="success" className="p-4 mb-0 text-center alert-grande">
        <h2>Cursos Disponíveis</h2>
      </Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Valor</th>
            <th>Data de Início</th>
            <th>Ativo</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {cursos.map((curso) => (
            <tr key={curso.id}>
              <td>{curso.nome}</td>
              <td>R$ {curso.valor}</td>
              <td>{ajustarFormatoData(curso.dataInicio)}</td>
              <td>
                {curso.ativo ? (
                  <FaCheck color="green" size={24} />
                ) : (
                  <FaTimes color="red" size={24} />
                )}
              </td>
              <td>
                <Link to={`/editarCurso/${curso.id}`}>
                  <Button variant="warning" className="mb-2"><RiPencilLine /></Button>
                </Link>{" "}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteCurso(curso)}
                  className="mb-2"
                >
                  <RiDeleteBin6Line />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`Tem certeza que deseja excluir o curso para ${nomeCursoToDelete}?`}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDeleteCurso}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </BaseScreen>
  );
};

export default ListaCursos;
