import { Button, Modal } from "react-bootstrap";

export const ModalDeSucesso = ({ showModal, closeModal, nome, isAluno }) => {
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="exampleModalLabel"
    >
      <Modal.Header closeButton>
        <Modal.Title>Cadastro Realizado com Sucesso! &#128512;</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ `Que ótimo, ${isAluno ? "aluno(a)" : "curso"} ${nome} foi cadastrado com sucesso em nossa base de dados!`}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={closeModal}>
          Concluir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
