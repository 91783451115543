import { useState } from "react";
import React from "react";

function Paginador(props) {
  const { paginaAtual, totalPages, onChangePagina } = props;

  const [maxVisibleButtons, setMaxVisibleButtons] = useState(5);
  
  if (totalPages < maxVisibleButtons) {
    setMaxVisibleButtons(totalPages);
  }

  const handleClick = (pageNumber) => {
    onChangePagina(pageNumber);
  };

  const renderButtons = () => {
    const buttons = [];

    const halfButtons = Math.floor(maxVisibleButtons / 2);
    let start = paginaAtual > halfButtons ? paginaAtual - halfButtons : 1;
    let end =
      paginaAtual + halfButtons > totalPages
        ? totalPages
        : paginaAtual + halfButtons;

    if (end - start < maxVisibleButtons - 1) {
      if (paginaAtual <= halfButtons) {
        end = maxVisibleButtons;
      } else {
        start = end - maxVisibleButtons + 1;
      }
    }

    for (let i = start; i <= end; i++) {
      const button = (
        <button
          key={i}
          className={`btn ${
            i === paginaAtual ? "btn-primary" : "btn-light"
          } ms-1`}
          onClick={() => handleClick(i)}
        >
          {i}
        </button>
      );
      buttons.push(button);
    }

    return buttons;
  };

  const handleNextPage = () => {
    if (paginaAtual < totalPages) {
      onChangePagina(paginaAtual + 1);
    }
  };

  const handlePrevPage = () => {
    if (paginaAtual > 1) {
      onChangePagina(paginaAtual - 1);
    }
  };

  return (
    <div className="d-flex justify-content-center mb-1">
      <button
        className="btn btn-light me-1"
        disabled={paginaAtual === 1}
        onClick={handlePrevPage}
      >
        {"<"}
      </button>

      {renderButtons()}

      <button
        className="btn btn-light ms-2"
        disabled={paginaAtual === totalPages}
        onClick={handleNextPage}
      >
        {">"}
      </button>
    </div>
  );
}

export { Paginador };
