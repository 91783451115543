const Input = ({
  colunaClass,
  labelFor,
  labelInput,
  placeholder,
  erro,
  errolabel,
  type,
  onChange,
  value,
  isObs,
}) => {
  const handleInputChange = (event) => {
    var value = event.target.value;
    onChange(value);
  };
  return (
    <div className={colunaClass}>
      <label htmlFor={labelFor} className="form-label">
        {labelInput}
      </label>
      <input
        type={type}
        className={"form-control"}
        id={labelFor}
        name={labelFor}
        required={isObs ? false : true}
        placeholder={placeholder}
        aria-describedby={erro}
        onChange={handleInputChange}
        value={value}
      />
      <div className="invalid-feedback" id={erro}>
        {errolabel}
      </div>
    </div>
  );
};

export default Input;
