import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("Alunos");

  const handleSelect = (selectedLink) => {
    setActiveLink(selectedLink);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">Cr Cursos Preparatórios</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={activeLink}
            onSelect={handleSelect}
          >
            <NavDropdown title="Cadastro" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/cadastroAluno">Alunos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cadastroCurso">Cursos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Listas" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/alunos">Alunos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cursos">Cursos</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
