export const SelectNivel = ({ setNivelEscolar }) => {
  return (
    <div className="col-md-4">
      <label htmlFor="escolaridade" className="form-label">
        Nível Escolar:
      </label>
      <select
        className="form-select"
        aria-label="Nível Escolar"
        aria-describedby="erroEscolaridade"
        name="escolaridade"
        onChange={setNivelEscolar}
        placeholder="Nivel Escolar"
      >
        <option defaultValue={""} style={{ color: "gray" }}>
          Nível Escolar...
        </option>
        <option value="Ensino Fundamental">Ensino Fundamental</option>
        <option value="Ensino Médio">Ensino Médio</option>
        <option value="Ensino Superior">Ensino Superior</option>
      </select>
      <div className="invalid-feedback" id="erroEscolaridade">
        Por favor, informe seu Nível Escolar!
      </div>
    </div>
  );
};
