import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiDeleteBin6Line,
  RiEyeLine,
  RiPencilLine,
  RiSearchLine,
} from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";
import "./styles.css";
import ReactInputMask from "react-input-mask";
import { Paginador } from "./paginador/paginador";

const Alunos = () => {
  const [alunos, setAlunos] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [alunoToDelete, setAlunoToDelete] = useState("");
  const [nomeAlunoToDelete, setNomeAlunoToDelete] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAlunoModal, setShowAlunoModal] = useState(false);
  const [alunoSelecionado, setAlunoSelecionado] = useState({});
  const [ordenacaoCrescente, setOrdenacaoCrescente] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("Nome");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPages, setTotalPages] = useState(-1);
  const [clicou, setClicou] = useState(false);

  const navigate = useNavigate();

  const getAlunos = async (ordenacaoCrescente) => {
    setIsLoading(true);
    if (ordenacaoCrescente === undefined) ordenacaoCrescente = "true";
    try {
      const response = await api({
        method: "get",
        url: `/alunos`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          pagina: paginaAtual,
          ordenacao: `${ordenacaoCrescente}`,
        },
      });

      setIsLoading(false);
      setAlunos(response.data.res);
      setTotalPages(response.data.totalPaginas);
    } catch (e) {
      setIsLoading(false);
      console.log(e.response);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/");
      return;
    }

    async function getCursos() {
      try {
        const response = await api({
          method: "get",
          url: `/cursos`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCursos(response.data);
      } catch (e) {
        console.log(e.response);
      }
    }

    getCursos();
    getAlunos();
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    getAlunos(ordenacaoCrescente);
    // eslint-disable-next-line
  }, [paginaAtual, ordenacaoCrescente]);

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    background-color: #fff;
  `;

  const confirmDeleteCurso = async () => {
    try {
      await api({
        method: "delete",
        url: `/alunos/${alunoToDelete}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAlunos((prevAlunos) =>
        prevAlunos.filter((aluno) => aluno.id !== alunoToDelete)
      );
      setAlunoToDelete(null);
      setShowModal(false);
    } catch (e) {
      console.log(e.response);
    }
  };

  const handleMostrarAluno = (aluno) => {
    setAlunoSelecionado(aluno);
    setShowAlunoModal(true);
  };

  const handleDeleteAluno = async (aluno) => {
    setAlunoToDelete(aluno.id);
    setNomeAlunoToDelete(aluno.nome);
    setShowModal(true);
  };

  function ajustarFormatoData(dataOriginal) {
    const data = new Date(dataOriginal);
    let dia = data.getDate() + 1;
    let mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    mes = mes <= 9 ? `0${mes}` : mes;
    dia = dia <= 9 ? `0${dia}` : dia;

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  }

  useEffect(() => {
    if (searchTerm.length === 0) {
      setClicou(false);
      getAlunos();
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    let nome = undefined;
    let cpf = undefined;
    let curso = undefined;

    if (searchBy === "Nome") {
      nome = searchTerm;
    } else if (searchBy === "CPF") {
      cpf = searchTerm;
    } else {
      curso = searchTerm;
    }

    try {
      const response = await api({
        method: "get",
        url: `/alunos/busca`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          nome: nome,
          cpf: cpf,
          curso: curso,
        },
      });
      setAlunos(response.data);
    } catch (e) {
      console.log(e.response);
    }
  };

  function handleChangePagina(numeroPagina) {
    setPaginaAtual(numeroPagina);
  }

  return (
    <BaseScreen>
      <Alert variant="success" className="p-4 mb-0 text-center alert-grande">
        <h2>Alunos Cadastrados</h2>
      </Alert>
      <Form className="my-4" onSubmit={handleFormSubmit}>
        <div className="input-wrapper d-flex justify-content-center">
          <Form.Select
            value={searchBy}
            onChange={(event) => {
              setSearchBy(event.target.value);
              setSearchTerm("");
            }}
            style={{ width: "10%" }}
            className="me-1"
          >
            <option value="Nome">Nome</option>
            <option value="CPF">CPF</option>
            <option value="Curso">Curso</option>
          </Form.Select>
          {searchBy === "Nome" ? (
            <Form.Control
              type="text"
              placeholder={"Pesquisar pelo nome do Aluno"}
              value={searchTerm}
              onChange={(text) => setSearchTerm(text.target.value)}
              style={{ width: "50%" }}
            />
          ) : searchBy === "CPF" ? (
            <ReactInputMask
              className="form-control"
              mask="999.999.999-99"
              placeholder="Pesquisar pelo CPF do Aluno"
              value={searchTerm}
              onChange={(text) => setSearchTerm(text.target.value)}
              style={{ width: "50%" }}
            />
          ) : (
            <Form.Select
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              style={{ width: "30%" }}
              className="me-1"
            >
              <option defaultValue={""} style={{ color: "gray" }}>
                Escolha um curso...
              </option>
              {cursos.map((curso) => (
                <option key={curso.id} value={curso.nome}>
                  {curso.nome}
                </option>
              ))}
            </Form.Select>
          )}

          <Button
            onClick={() => {
              handleSearch();
              setClicou(true);
            }}
            className="ms-1"
            disabled={!searchTerm}
          >
            <RiSearchLine className="search-icon" />
          </Button>
        </div>
      </Form>

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
        </LoadingContainer>
      ) : alunos.length === 0 ? (
        <div className="p-4 rounded-3">
          <Alert variant="warning" className="mb-0 text-center">
            <p>Não foi encontrado nenhum aluno na busca</p>
          </Alert>
        </div>
      ) : (
        <>
          {!clicou && (
            <Paginador
              paginaAtual={paginaAtual}
              totalPages={totalPages}
              onChangePagina={handleChangePagina}
            />
          )}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  Nome
                  <Button
                    variant="link"
                    onClick={() => {
                      setOrdenacaoCrescente(!ordenacaoCrescente);
                    }}
                  >
                    {ordenacaoCrescente ? (
                      <RiArrowUpSLine />
                    ) : (
                      <RiArrowDownSLine />
                    )}
                  </Button>
                </th>
                <th>CPF</th>
                <th className="hide-on-mobile">Telefone</th>
                <th className="hide-on-mobile">Email</th>
                <th className="hide-on-mobile">Cursos</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {alunos.map((aluno) => (
                <tr key={aluno.id}>
                  <td>{aluno.nome}</td>
                  <td>{aluno.cpf}</td>
                  <td className="hide-on-mobile">{aluno.numTelefone}</td>
                  <td className="hide-on-mobile">{aluno.email}</td>
                  <td className="hide-on-mobile">
                    <ul className="list-unstyled">
                      {aluno.Cursos?.map((cursoMapped) => {
                        const curso = cursos.find(
                          (curso) => curso.id === cursoMapped.id
                        );
                        return (
                          <li key={cursoMapped.id}>
                            {curso ? (
                              <span className="badge bg-primary mt-2">
                                {curso.nome}
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                Curso não encontrado
                              </span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </td>

                  <td className="action-buttons">
                    <Link to={`/editarAluno/${aluno.id}`}>
                      <Button variant="warning" className="mb-2 me-2">
                        <RiPencilLine />
                      </Button>
                    </Link>{" "}
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteAluno(aluno)}
                      className="mb-2 me-2"
                    >
                      <RiDeleteBin6Line />
                    </Button>{" "}
                    <Button
                      variant="info"
                      className="mb-2 me-2"
                      onClick={() => handleMostrarAluno(aluno)}
                    >
                      <RiEyeLine />{" "}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`Os dados do aluno(a) ${nomeAlunoToDelete} serão excluídos permanentemente. Deseja continuar?`}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDeleteCurso}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlunoModal} onHide={() => setShowAlunoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Informações do Aluno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Nome:</strong> {alunoSelecionado.nome}
          </p>
          <p>
            <strong>CPF:</strong> {alunoSelecionado.cpf}
          </p>
          <p>
            <strong>Data de Nascimento:</strong>{" "}
            {ajustarFormatoData(alunoSelecionado.dataNascimento)}
          </p>
          <p>
            <strong>Endereço:</strong> {alunoSelecionado.endereco}
          </p>
          <p>
            <strong>CEP:</strong> {alunoSelecionado.cep}
          </p>
          <p>
            <strong>Cidade:</strong> {alunoSelecionado.cidade}
          </p>
          <p>
            <strong>UF:</strong> {alunoSelecionado.uf}
          </p>
          <p>
            <strong>Número Residencial:</strong>{" "}
            {alunoSelecionado.numResidencial}
          </p>
          <p>
            <strong>Número Telefone:</strong> {alunoSelecionado.numTelefone}
          </p>
          <p>
            <strong>E-mail:</strong> {alunoSelecionado.email}
          </p>
          <p>
            <strong>Nível Escolar:</strong> {alunoSelecionado.nivelEscolar}
          </p>
          <p>
            <strong>Cursos:</strong>{" "}
            <ul className="list-unstyled">
              {alunoSelecionado.Cursos?.map((cursoMapped) => {
                const curso = cursos.find(
                  (curso) => curso.id === cursoMapped.id
                );
                return (
                  <li key={cursoMapped.id}>
                    {curso ? (
                      <span className="badge bg-primary">{curso.nome}</span>
                    ) : (
                      <span className="badge bg-danger">
                        Curso não encontrado
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </p>
          <p>
            <strong>Observações:</strong> {alunoSelecionado.observacao}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlunoModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </BaseScreen>
  );
};

export default Alunos;
