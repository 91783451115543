import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  background-color: #fff;
`;

const EditarCursoContainer = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  margin: 0 auto;
  max-width: 85%;
`;

const EditarCurso = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [curso, setCurso] = useState({
    nome: "",
    valor: "",
    dataInicio: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getCurso() {
      try {
        const response = await api.get(`/cursos/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCurso(response.data);
        setIsLoading(false);
      } catch (e) {
        console.log(e.response);
        setError(e.response.data.message);
        setIsLoading(false);
      }
    }
    getCurso();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.put(`/cursos/${id}`, curso, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      navigate("/cursos");
    } catch (e) {
      console.log(e.response);
      setError(e.response.data.message);
    }
  };  

  const handleChange = (event) => {
    setCurso({
      ...curso,
      [event.target.name]: event.target.value,
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
      </LoadingContainer>
    );
  }

  return (
    <BaseScreen>
      {error && <Alert variant="danger">{error}</Alert>}
      <EditarCursoContainer>
        <h2>Editar Curso</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="nome"
              value={curso.nome}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="text"
              name="valor"
              value={curso.valor}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Data de Início</Form.Label>
            <Form.Control
              type="date"
              name="dataInicio"
              value={curso.dataInicio}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label>Ativo</Form.Label>
            <Form.Check
              type="switch"
              id="custom-switch"
              name="ativo"
              label={curso.ativo ? "Ativado" : "Desativado"}
              checked={curso.ativo}
              onChange={(event) => {
                setCurso({
                  ...curso,
                  ativo: event.target.checked,
                });
              }}
            />
          </Form.Group>

          <Button type="submit" className="mt-3">
            Salvar
          </Button>
          <Button type="button" className="mt-3 ms-3 btn btn-secondary" as={Link} to="/cursos">
            Voltar
          </Button>
        </Form>
      </EditarCursoContainer>
    </BaseScreen>
  );
};

export default EditarCurso;
