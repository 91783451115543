import { Route, Routes, useNavigate } from "react-router-dom";
import EditarAluno from "./screens/editarAluno/index.js";
import EditarCurso from "./screens/editarCurso/index.js";
import Form from "./screens/formularioAluno/index.js";
import CadastrarCurso from "./screens/formularioCurso/index.js";
import Alunos from "./screens/listaAlunos/index.js";
import ListaCursos from "./screens/listaCursos/index.js";
import Login from "./screens/login/index.js";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

function App() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      if (payload.exp < currentTime) {
        setShowModal(true);
      }
    }
  }, [navigate]);

  function navigateToLogin() {
    setShowModal(false);
    return navigate("/");
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/cadastroAluno" element={<Form />} />
        <Route path="/cursos" element={<ListaCursos />} />
        <Route path="/editarCurso/:id" element={<EditarCurso />} />
        <Route path="/alunos" element={<Alunos />} />
        <Route path="/editarAluno/:id" element={<EditarAluno />} />
        <Route path="/cadastroCurso" element={<CadastrarCurso />} />
      </Routes>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="exampleModalLabel"
      >
        <Modal.Header closeButton>
          <Modal.Title>Acesso Expirado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          O tempo de acesso do seu token expirou, você será redirecionado para
          realizar o login novamente!
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={navigateToLogin}>Entendi</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
