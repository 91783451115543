export const SelectEstado = ({ setEstado, value }) => {
  return (
    <div className="col-md-2">
      <label htmlFor="estado" className="form-label">
        UF
      </label>
      <select
        className="form-select"
        aria-label="Estados Brasileiros"
        aria-describedby="erroEstado"
        name="estado"
        onChange={setEstado}
        value={value}
      >
        <option defaultValue={""} style={{ color: "gray" }}>
          Selecione a UF...
        </option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
        <option value="EX">Estrangeiro</option>
      </select>
      <div className="invalid-feedback" id="erroEstado">
        Por favor, informe sua UF!
      </div>
    </div>
  );
};
