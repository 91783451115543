import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BaseScreen } from "../../components/BaseScreen";
import api from "../../service";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  background-color: #fff;
`;

const EditarAlunoContainer = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  margin: 0 auto;
  max-width: 85%;
`;

const EditarAluno = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [aluno, setAluno] = useState({
    nome: "",
    cpf: "",
    numTelefone: "",
    email: "",
    cursoId: "",
    observacao: "",
    Cursos: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cursos, setCursos] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [cursosSelecionados, setCursosSelecionados] = useState([]);

  async function getAluno() {
    try {
      const response = await api({
        method: "get",
        url: `/alunos/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAluno(response.data);
      setCursosSelecionados(response.data.Cursos.map((curso) => curso.id));
      setIsLoading(false);
    } catch (e) {
      console.log(e.response);
      setIsLoading(false);
    }
  }

  async function getCursos() {
    try {
      const response = await api({
        method: "get",
        url: `/cursos`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCursos(response.data);
    } catch (e) {
      console.log(e.response);
    }
  }

  useEffect(() => {
    async function loadData() {
      await getCursos();
      await getAluno();
      setIsDataLoaded(true);
    }

    loadData();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await api({
        method: "put",
        url: `/alunos/${id}`,
        data: {
          nome: aluno.nome,
          cpf: aluno.cpf,
          numTelefone: aluno.numTelefone,
          email: aluno.email,
          cursosIds: cursosSelecionados,
          observacao: aluno.observacao,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      navigate("/alunos");
    } catch (e) {
      console.log(e.response);
      setError(e.response.data.message);
    }
  };

  const handleChange = (event) => {
    setAluno({
      ...aluno,
      [event.target.name]: event.target.value,
    });
  };

  if (!isDataLoaded && isLoading) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
      </LoadingContainer>
    );
  }

  return (
    <BaseScreen>
      {error && <Alert variant="danger">{error}</Alert>}
      <EditarAlunoContainer>
        <h2>Editar Aluno</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="nome"
              value={aluno.nome}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>CPF</Form.Label>
            <Form.Control
              type="text"
              name="cpf"
              value={aluno.cpf}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              name="numTelefone"
              value={aluno.numTelefone}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={aluno.email}
              onChange={handleChange}
            />
          </Form.Group>

          <div className="col-md-12 mt-3">
            <label htmlFor="nomeCurso" className="form-label">
              Cursos
            </label>
            <div style={{ columnCount: 3 }}>
              {cursos.map(
                (curso) =>
                  curso.ativo === 1 && (
                    <div key={curso.id} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`curso-${curso.id}`}
                        value={curso.id}
                        onChange={(e) => {
                          const cursoId = e.target.value;
                          if (cursosSelecionados.includes(Number(curso.id))) {
                            setCursosSelecionados(
                              cursosSelecionados.filter(
                                (id) => id !== Number(cursoId)
                              )
                            );
                          } else {
                            setCursosSelecionados([
                              ...cursosSelecionados,
                              Number(cursoId),
                            ]);
                          }
                        }}
                        checked={cursosSelecionados.includes(Number(curso.id))}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`curso-${curso.id}`}
                      >
                        {curso.nome}
                      </label>
                    </div>
                  )
              )}
            </div>
          </div>

          <Form.Group className="mt-3">
            <Form.Label>Observações</Form.Label>
            <Form.Control
              type="text"
              name="observacao"
              value={aluno.observacao}
              onChange={handleChange}
            />
          </Form.Group>

          <Button type="submit" className="mt-3">
            Salvar
          </Button>
          <Button type="button" className="mt-3 ms-3 btn btn-secondary" as={Link} to="/alunos">
            Voltar
          </Button>
        </Form>
      </EditarAlunoContainer>
    </BaseScreen>
  );
};

export default EditarAluno;
